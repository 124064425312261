import React from "react";
import { Link } from "react-scroll";

// Sections
import Hero from "../../components/sections/Hero";
import HeroSeparator from "../../components/sections/HeroSeparator";
import Startups from "../../components/sections/Startups";
import SuccessStories from "../../components/sections/SuccessStories";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts";
import { startUpSuccessStoriesData } from "../../components/sucessStoriesData";

// Images
import bgHero from "../../assets/images/bg-startups.jpg";
import lineImg from "../../assets/images/work-icon.svg";

const Team = () => {
	return (
		<Layout>
			<Hero hero={bgHero} layout="extended">
				<div className="container h-100 ">
					<div className="hero__inner">
						<h1 className="h1">
							<span>We love</span>
							<span>working</span>
							<span>with</span>
              <span>startups</span>
						</h1>
						<div className="hero__content">
              <p>
								If you’re a startup and looking for advice on your technology strategy, get in touch. No matter how small your business is right now, we’d love to hear from you and see
								what we could do to help.
							</p>
							<p>We’re pretty good at it, too. Take a look below at some of the startups we’ve helped grow into multi-million pound businesses.</p>
						</div>
					</div>
				</div>
			</Hero>
			<HeroSeparator image={lineImg} />
			
      <SuccessStories
        id="success-stories"
        storiesDataStatic={startUpSuccessStoriesData}
      />
			<Startups id="process" />
			<Cta />
		</Layout>
	);
};

export default Team;