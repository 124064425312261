import React from "react";

const Startups = ({ id }) => {
	return (
		<section className="section-startups" id={id}>
			<div className="container">
				<div className="section-startups__process">
					<div className="row">
						<div className="col-lg-5 mx-auto">
							<div className="section-startups__process__box">
								<h5>Our Process</h5>
								<h4>The ethos of being a lean startup</h4>
								<ul>
									<li>&#8226; Test your underpinning assumptions in order to shape and validate the product you build</li>
									<li>&#8226; Be lightweight, agile and pivot your way to success</li>
									<li>&#8226; Prove you have a scalable business model with data</li>
									<li>&#8226; Be evidence-based and willing to adapt according to insight</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="section-startups__boxes">
					<div className="row">
						<div className="col-xl-4 col-lg-6">
							<div className="section-startups__boxes--box">
								<h5>Problem solution fit</h5>
								<p className="color-primary">Test your underpinning assumptions in order to shape and validate the product you build</p>
								<ul>
									<li>Be lightweight, agile and pivot your way to success</li>
									<li>Prove you have a scalable business model with data</li>
									<li>Be evidence-based and willing to adapt according to insight</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6">
							<div className="section-startups__boxes--box">
								<h5>Product &amp; Market Fit</h5>
								<p className="color-primary">I.e. Prove there is a market for your idea and that you have a scalable business model</p>
								<ul>
									<li>You keep the numbers low (i.e. cheap) at this stage, but it’s still testing the model</li>
									<li>Try, learn and adapt – focusing on the area of your funnel that isn’t doing what you hoped it would</li>
									<li>Perhaps it cheaply and quickly reveals a problem – an all important pivot e.g. assuming people wanted something they don’t</li>
									<li>Perhaps it just cheaply irons out the creases and once the ratios perform as we hoped, it’s time to talk scaling and upping acquisition investment</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6">
							<div className="section-startups__boxes--box">
								<h5>A product managed process</h5>
								<p className="color-primary">
									Realise your vision while ensuring all of our efforts are hyper focused on the activities with the biggest impact/least effort relative to the stage we’re in
								</p>
								<ul>
									<li>Minimise wasted investment</li>
									<li>Become profitable/scalable before money runs out</li>
									<li>The team agrees the focus and tasks for each sprint then stick to it – no distraction outside of that = efficient progress</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="section-startups__design-approach">
					<div className="row">
						<div className="col-12">
							<h5>Design Approach</h5>
							<h4>Discover, Define, Develop, Deliver</h4>
							<div className="section-startups__design-approach--infographic">
								<h5>Problem</h5>
								<div>
									<h5>
										Research,
										<br />
										Analyse,
										<br />
										Synthesize
									</h5>
								</div>
								<h5>Definition</h5>
								<div>
									<h5>
										Prototype,
										<br />
										Test,
										<br />
										Refine
									</h5>
								</div>
								<h5>Solution</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="section-startups__startup-success-stories">
					<div className="row">
						<div className="col-md-8 col-lg-5 mx-auto">
							<h5>Startup success stories</h5>
							<p>We’re pretty good at our process. Take a look at these startups we’ve helped grow into multi-million pound businesses.</p>
							<div className="section-startups__startup-success-stories--links">
								<div>
									<a href="https://letsmindstep.com">
										<h4>letsmindstep.com</h4>
									</a>
								</div>								
								<div>
									<a href="https://tendo.id">
										<h4>tendo.id</h4>
									</a>
								</div>
								<div>
									<a href="https://shinc.io">
										<h4>shinc.io</h4>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Startups;
